import React from 'react'
import './index.css'

const LoaderMythos = () => {
  return (
    <div className="mythosloader">
      <img src="/images/landing/sec-2-circle.svg" width="250" alt="" />
    </div>
  )
}

export default LoaderMythos